import * as React from "react"
import styled from "styled-components"
import { FC, useEffect, useState } from "react"
import { filterByVisibility } from "../../util/productUtil"
import algoliasearch from "algoliasearch"
import ProductCarousel from "./ProductCarousel"
import { AlgoliaProductType, ProductType } from "@social-supermarket/social-supermarket-model"
import { mapAlgoliaProduct } from "@social-supermarket/social-supermarket-components"

const searchClient = algoliasearch("BLA29WX2H4", "253a575f90629780f8b2001e0dfe8264")
const productIndex = searchClient.initIndex("products")

const Container = styled.div``

interface Props {
  collectionName: string
  title?: string
  noLoop?: boolean
  large?: boolean
  showImpact?: boolean
  hidePrice?: boolean
  hideBrand?: boolean
  noLink?: boolean
  containerStyle?: object
}

const AlgoliaProductCarousel: FC<Props> = ({
  collectionName,
  title,
  noLoop,
  large,
  showImpact,
  hidePrice,
  hideBrand,
  noLink,
  containerStyle,
}) => {
  const [products, setProducts] = useState<ProductType[]>([])

  useEffect(() => {
    updateProducts()
  }, [])

  const updateProducts = async () => {
    const response = await productIndex.search<AlgoliaProductType>("", {
      facetFilters: [`collections:${collectionName}`],
      hitsPerPage: 200,
      clickAnalytics: true,
    })

    setProducts(response.hits.map(mapAlgoliaProduct))
  }

  return (
    <ProductCarousel
      products={filterByVisibility(products, "Marketplace")}
      title={title}
      noLoop={noLoop}
      large={large}
      showImpact={showImpact}
      hidePrice={hidePrice}
      hideBrand={hideBrand}
      noLink={noLink}
      containerStyle={containerStyle}
    />
  )
}

export default AlgoliaProductCarousel
